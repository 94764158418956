export const fetchAll = async (token: string) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/places`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const { data: places, message } = await res.json();

    return places || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const fetchPlace = async (token: string, slug: string) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/places/${slug}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const { data: place, message } = await res.json();

    return place || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const addPlace = async (
  token: string,
  title: string,
  food: string,
  desc: string,
  photoUrl: string
) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/places`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title, food, desc, photoUrl }),
    });

    const { message, success } = await res.json();

    return message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const updatePlace = async (token: string, place: Place) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/places/${place.slug}`,
      {
        method: "PUT",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...place }),
      }
    );

    const { message, success } = await res.json();

    return message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const deletePlace = async (token: string, place: Place) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/places/${place.slug}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    const { message, success } = await res.json();

    return message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const addReview = async (
  token: string,
  slug: string,
  rating: number,
  comment: string
) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/places/${slug}/reviews`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rating, comment }),
      }
    );

    const { message, success } = await res.json();

    return success || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const updateReview = async (
  token: string,
  slug: string,
  review: Review
) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/places/${slug}/reviews/${review.id}`,
      {
        method: "PUT",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...review }),
      }
    );

    const { message, success } = await res.json();

    return success || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const deleteReview = async (
  token: string,
  slug: string,
  review: Review
) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/places/${slug}/reviews/${review.id}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    const { success, message } = await res.json();

    return success || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};
