import React, { FC } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import useUser from "../../hooks/useUser";

const Nav: FC = () => {
  const [{ user }, { onSignOut }] = useUser();

  return (
    <NavStyled>
      <Link title="Home" className="logo" to="/">
        Cool Places
      </Link>
      <ul className="links">
        {user?.isAdmin && (
          <>
            <li>
              <Link title="Add place" to="/manage/places">
                Add place
              </Link>
            </li>
            <li>
              <Link title="Manage users" to="/manage/users">
                Manage users
              </Link>
            </li>
          </>
        )}
        {!!user && <li onClick={onSignOut}>Sign out</li>}
      </ul>
    </NavStyled>
  );
};

const NavStyled = styled.nav`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  justify-content: space-between;
  .logo {
    color: ${({ theme }) => theme.colors.black};
    font-family: Romanesco;
    font-size: 2.2rem;
    line-height: 1;
  }
  .links,
  .links a {
    color: ${({ theme }) => theme.colors.main};
    cursor: pointer;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      &:not(:first-of-type) {
        margin-left: 1rem;
      }
    }
  }
`;

export default Nav;
