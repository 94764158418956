import styled from "@emotion/styled/macro";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/app/Footer";
import Nav from "./components/app/Nav";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import PlacePage from "./pages/PlacePage";
import ManagePlace from "./pages/ManagePlace";
import ListUsers from "./pages/ListUsers";
import ManageUser from "./pages/ManageUser";

function App() {
  return (
    <Router>
      <AppStyled>
        <Nav></Nav>
        <Main>
          <Switch>
            <Route exact path="/register">
              <AuthPage isInCreatingMode></AuthPage>
            </Route>
            <Route exact path="/login">
              <AuthPage></AuthPage>
            </Route>
            <Route exact path="/">
              <HomePage></HomePage>
            </Route>
            <Route exact path="/places/:slug">
              <PlacePage></PlacePage>
            </Route>
            <Route exact path="/manage/places/:slug?">
              <ManagePlace></ManagePlace>
            </Route>
            <Route exact path="/manage/users">
              <ListUsers></ListUsers>
            </Route>
            <Route exact path="/manage/users/:email">
              <ManageUser></ManageUser>
            </Route>
          </Switch>
        </Main>
        <Footer></Footer>
      </AppStyled>
    </Router>
  );
}

const AppStyled = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  @media (min-width: 900px) {
    border: 1.4rem solid #f7f7f7;
  }
`;

const Main = styled.main`
  width: calc(900px + 2 * ${({ theme }) => theme.gutter});
  max-width: 100vw;
  margin: 2rem auto 0;
  display: grid;
  justify-content: center;
  row-gap: 1rem;
  grid-template-columns: ${({ theme }) => theme.gutter} 1fr ${({ theme }) =>
      theme.gutter};
  & > * {
    grid-column: 2 / -2;
  }
  & > .full {
    grid-column: span 3;
  }
  .gap-in-full {
    padding: 0 ${({ theme }) => theme.gutter};
  }
`;

export default App;
