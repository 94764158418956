import styled from "@emotion/styled/macro";
import React, { FC, FormEvent, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import PlaceList from "../components/places/PlaceList";
import usePlaces from "../hooks/usePlaces";
import useUser from "../hooks/useUser";

const HomePage: FC = ({} = {}) => {
  const [{ user }] = useUser();

  const [{ places, isLoading, error }] = usePlaces();

  if (!user) {
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <Page>
      <header>
        <LogoHero>
          <strong>Cool Places</strong> The best restaurants
        </LogoHero>
      </header>
      <section className="full">
        {!!error && <p>Something went wrong while searching</p>}
        {!!isLoading && <p>Loading...</p>}
        {!isLoading && <PlaceList places={places}></PlaceList>}
      </section>
    </Page>
  );
};

const Page = styled.div`
  section {
    margin-top: 3rem;
  }
`;

const LogoHero = styled.h1`
  color: ${({ theme }) => theme.colors.main};
  font-size: 1.3rem;
  font-weight: normal;
  text-align: center;
  strong {
    font-family: Romanesco;
    font-size: 7rem;
    font-weight: normal;
    display: block;
    line-height: 1;
  }
`;

export default HomePage;
