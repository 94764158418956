export const signIn = async (email: string, password: string) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const { data: user, message } = await res.json();

    return user || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const register = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password }),
    });

    const { data: user, message } = await res.json();

    return user || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};
