import styled from "@emotion/styled/macro";
import { Cloudinary } from "cloudinary-core";
import React, { FC, FormEvent, useEffect, useState } from "react";
import { Redirect, Link, useParams } from "react-router-dom";
import { addPlace, fetchPlace, updatePlace, deletePlace } from "../api/places";
import Icon from "../components/app/Icon";
import TextInput from "../components/app/TextInput";
import useUser from "../hooks/useUser";
import settings from "../settings";
import { Button } from "../styling/Buttons";
import { Title1 } from "../styling/Titles";

const cl = new Cloudinary({
  cloud_name: settings.cloudinary.name,
  secure: true,
});

interface Props {
  placeSlug?: Place["slug"];
}

const ManagePlace: FC<Props> = ({ placeSlug = "" } = {}) => {
  const [{ user }] = useUser();

  const { slug: routeSlug } = useParams<{ slug: string }>();
  const slug = placeSlug || routeSlug;

  const [editingPlace, setEditingPlace] = useState<Place | null>(null);
  const [isLoadingPlace, setIsLoadingPlace] = useState(!!slug);
  const [title, setTitle] = useState("");
  const [food, setFood] = useState("");
  const [desc, setDesc] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [message, setMessage] = useState("");
  const [isDeletingPlace, setIsDeletingPlace] = useState(false);

  useEffect(() => {
    onFetch();
  }, []);

  if (!user) {
    return <Redirect to="/login"></Redirect>;
  }

  const onFetch = async () => {
    if (!user || !user.token || !slug) {
      return;
    }

    const result = await fetchPlace(user?.token, slug);

    if (result && result.slug) {
      setEditingPlace(result);
      setTitle(result.title);
      setFood(result.food);
      setDesc(result.desc);
      setPhotoUrl(result.photoUrl);
    }

    setIsLoadingPlace(false);
  };

  const onImageUpload = async (e: any) => {
    setIsUploadingImage(true);
    try {
      const files = e.target.files;
      const data = new FormData();
      data.append("file", files[0]);
      data.append("upload_preset", "default");

      const res = await fetch(
        `https://api.cloudinary.com/v1_1/${settings.cloudinary.name}/image/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const uploadData = await res.json();
      console.log(uploadData);

      const { secure_url } = uploadData;
      setPhotoUrl(secure_url || "");
    } catch (error) {
      console.error(error);
    }
    setIsUploadingImage(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!user || !user.token) {
      return;
    }

    setIsSaving(true);

    if (slug) {
      const result = await updatePlace(user.token, {
        ...editingPlace,
        title,
        food,
        desc,
        photoUrl,
      } as Place);

      setMessage(result);
    } else {
      const result = await addPlace(user.token, title, food, desc, photoUrl);

      setMessage(result);
    }

    setIsSaving(false);
  };

  const onRemove = async () => {
    if (!user || !user.token || !editingPlace) {
      return;
    }

    setIsDeletingPlace(true);

    const result = await deletePlace(user.token, editingPlace);
    setMessage(result);

    setIsDeletingPlace(false);
  };

  return (
    <Page>
      <Title1>{slug ? "Edit place" : "Add place"}</Title1>
      <p className="result">{message}</p>
      <Form onSubmit={onSubmit}>
        <PhotoPicker>
          <label htmlFor="thumb-upload">Imagem</label>
          <input
            type="file"
            id="thumb-upload"
            name="thumb-upload"
            placeholder="Upload an image"
            onChange={onImageUpload}
          />
          {isUploadingImage && <span>uploading...</span>}
          {photoUrl && (
            <img
              src={cl.url(photoUrl, {
                width: 400,
                height: 300,
                crop: "fill",
              })}
              alt="Photo picked"
            />
          )}
        </PhotoPicker>
        <Input
          label="Title"
          placeHolder="Ex: Sushi House"
          value={title}
          name="title"
          onChange={(e) => setTitle(e.currentTarget.value)}
        ></Input>
        <Input
          label="Culinary"
          placeHolder="Ex: Japanese"
          value={food}
          name="food"
          onChange={(e) => setFood(e.currentTarget.value)}
        ></Input>
        <Input
          label="Description"
          placeHolder="Ex: The best place around"
          value={desc}
          name="desc"
          textarea
          onChange={(e) => setDesc(e.currentTarget.value)}
        ></Input>
        <Button
          data-testid="submit"
          disabled={isSaving || !title || !desc || !photoUrl || !food}
          type="submit"
        >
          {isSaving ? "Wait..." : "Save"}
        </Button>
        {!!slug && (
          <span className="remove" onClick={onRemove}>
            <Icon icon="bin" size="1rem"></Icon>
            {isDeletingPlace ? "Removing..." : "Remove place"}
          </span>
        )}
      </Form>
    </Page>
  );
};

const Page = styled.div`
  .result {
    color: ${(p) => p.theme.colors.main};
    font-weight: bold;
  }
  .remove {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: grid;
  gap: 1rem;
  max-width: 30rem;
  margin-top: 2rem;
  .validation {
    color: red;
  }
  .redirect {
    text-align: right;
  }
`;

const Input = styled(TextInput)`
  label {
    color: inherit;
  }
`;

const PhotoPicker = styled.div`
  display: grid;
  img {
    margin-top: 0.5rem;
    width: 7rem;
    height: 5rem;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.border.small};
  }
`;

export default ManagePlace;
