import { useContext, useState } from "react";
import { UserContext, signInUser, signOutUser } from "../context/UserContext";
import { signIn, register } from "../api/auth";

type OnSignIn = (email: string, password: string) => void;
type OnRegister = (name: string, email: string, password: string) => void;
type OnSignOut = () => void;

const useUser = (): [
  {
    user: User | null;
    isLoading: boolean;
    error: string;
  },
  {
    onSignIn: OnSignIn;
    onRegister: OnRegister;
    onSignOut: OnSignOut;
  }
] => {
  const [state, dispatch] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const onSignIn: OnSignIn = async (email, password) => {
    setIsLoading(true);

    const result = await signIn(email, password);

    if (result && result.email) {
      dispatch(signInUser(result));
    } else {
      setError(result);
    }

    setIsLoading(false);
  };

  const onRegister: OnRegister = async (name, email, password) => {
    setIsLoading(true);

    const result = await register(name, email, password);

    if (result && result.email) {
      dispatch(signInUser(result));
    } else {
      setError(result);
    }

    setIsLoading(false);
  };

  const onSignOut: OnSignOut = () => {
    dispatch(signOutUser());
  };

  return [
    {
      ...state,
      isLoading,
      error,
    },
    {
      onSignIn,
      onRegister,
      onSignOut,
    },
  ];
};

export default useUser;
