import styled from "@emotion/styled/macro";
import React, { FC } from "react";
import Icon from "./Icon";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const Modal: FC<Props> = ({ onClose, children, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <Wrapper>
      <div className="shadow" onClick={onClose}></div>
      <div className="box">
        <div className="top">
          <span data-testid="close" onClick={onClose}>
            <Icon size="1rem" icon="cancel-circle"></Icon>
          </span>
        </div>
        <div className="body">{children}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  .box {
    background: white;
    padding: 1rem;
    border-radius: ${(p) => p.theme.border.normal};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 20rem;
  }
  .top {
    svg {
      fill: ${(p) => p.theme.colors.greyEvenDarker};
    }
    text-align: right;
    color: ${(p) => p.theme.colors.text};
    span {
      cursor: pointer;
    }
  }
`;

export default Modal;
