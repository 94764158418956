import styled from "@emotion/styled/macro";
import React, { FC } from "react";

const Footer: FC = () => {
  return <FooterStyled>Cool Places</FooterStyled>;
};

const FooterStyled = styled.footer`
  padding: 1rem;
  text-align: center;
`;

export default Footer;
