export const calculateRating = (
  place: Place
): Place & { averageRating: number } => ({
  ...place,
  averageRating: place?.reviews?.length
    ? place.reviews.reduce((acum, review) => acum + review.rating, 0) /
      place.reviews.length
    : 0,
});

export const calculateRatingForPlaces = (places: Place[]) =>
  places.map((place) => calculateRating(place));

export const sortBestRated = (places: Place[]) => {
  const withAverages = calculateRatingForPlaces(places);
  return withAverages.sort((a, b) =>
    a.averageRating > b.averageRating ? -1 : 1
  );
};

export const getDetailedRatings = (
  place: Place
): Place & {
  averageRating: number;
  highestRatedReview: Review | null;
  lowestRatedReview: Review | null;
  latestReview: Review | null;
} => {
  const withAverage = calculateRating(place);

  const sortedReviewsByRating = [...place.reviews].sort((a, b) =>
    a.rating > b.rating ? -1 : 1
  );

  const [latestReview] = place.reviews.sort((a, b) =>
    a.date > b.date ? -1 : 1
  );

  return {
    ...withAverage,
    highestRatedReview: sortedReviewsByRating[0],
    lowestRatedReview: sortedReviewsByRating.reverse()[0],
    latestReview,
  };
};
