import styled from "@emotion/styled/macro";
import React, { FC, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { fetchAll } from "../api/users";
import useUser from "../hooks/useUser";
import { Title1 } from "../styling/Titles";

const ListUsers: FC = () => {
  const [{ user }] = useUser();

  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    onFetchAll();
  }, []);

  if (!user || !user.isAdmin) {
    return <Redirect to="/login"></Redirect>;
  }

  const onFetchAll = async () => {
    if (!user || !user.token) {
      return;
    }
    setIsLoading(true);

    const result = await fetchAll(user?.token);

    if (result && result.length) {
      setUsers(result);
    } else {
      setError(result);
    }

    setIsLoading(false);
  };

  const onPickUser = (user: User) => {
    history.push(`/manage/users/${user.email}`);
  };

  return (
    <Page>
      <div>
        <Title1>Users</Title1>
        <span className="info">Click on a user to manage</span>
      </div>
      {!!error && <p>Something went wrong while searching</p>}
      {!!isLoading && <p>Loading...</p>}
      {!isLoading && (
        <Table cellSpacing="0">
          <TableHeader>
            <tr>
              <TableHeaderTitle>name</TableHeaderTitle>
              <TableHeaderTitle>email</TableHeaderTitle>
              <TableHeaderTitle>is admin</TableHeaderTitle>
            </tr>
          </TableHeader>
          <TableBody>
            {users.map((user) => (
              <tr key={user.email} onClick={() => onPickUser(user)}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.isAdmin ? "yes" : "no"}</TableCell>
              </tr>
            ))}
          </TableBody>
        </Table>
      )}
    </Page>
  );
};

const Page = styled.div`
  display: grid;
  gap: 1rem;
  .info {
    color: ${(p) => p.theme.colors.main};
  }
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.greyDarker};
  border-radius: ${(p) => p.theme.border.normal};
  padding: 1rem;
`;

const TableHeader = styled.thead`
  text-align: left;
`;

const TableHeaderTitle = styled.th`
  cursor: pointer;
  padding: 0 0 1rem;
`;

const TableBody = styled.tbody`
  tr {
    &:first-of-type td {
      margin-top: 1rem;
    }
    &:not(:last-of-type) td {
      border-bottom: 1px solid ${({ theme }) => theme.colors.greyDarker};
    }
    &:hover {
      cursor: pointer;
      td {
        background: ${({ theme }) => theme.colors.main};
        color: white;
        &:first-of-type {
          border-radius: ${({ theme }) => theme.border.normal} 0 0
            ${({ theme }) => theme.border.normal};
        }
        &:last-of-type {
          border-radius: 0 ${({ theme }) => theme.border.normal}
            ${({ theme }) => theme.border.normal} 0;
        }
      }
    }
  }
`;

const TableCell = styled.td`
  transition: all 0.2s;
  padding: 1rem 0.5rem;
`;

export default ListUsers;
