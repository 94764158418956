import React, { useReducer, useEffect, createContext, FC } from "react";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../helpers/localstorage";

interface State {
  user: User | null;
}

interface Action {
  type: string;
  data: any;
}

interface Props {
  forcedState?: State | null;
}

export type UserContextType = [State, (action: any) => void];

const STORAGE_KEY = "user";

const USER_SIGNED_IN = "USER_SIGNED_IN";
const USER_SIGNED_OUT = "USER_SIGNED_OUT";

const initialState: State = {
  user: null,
};

export const signInUser = (user: User) => ({
  type: USER_SIGNED_IN,
  data: user,
});

export const signOutUser = () => ({
  type: USER_SIGNED_OUT,
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case USER_SIGNED_IN:
      return {
        user: action.data,
      };
    case USER_SIGNED_OUT:
      return {
        user: null,
      };
    default:
      throw new Error("Wrong action");
  }
};

export const UserContextProvider: FC<Props> = ({ children, forcedState }) => {
  const stored = getFromLocalStorage(STORAGE_KEY);

  const [state, dispatch] = useReducer(
    reducer,
    forcedState || stored || initialState
  );

  useEffect(() => {
    saveToLocalStorage(STORAGE_KEY, state);
  }, [state]);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

//@ts-ignore
export const UserContext = createContext<UserContextType>();
