import styled from "@emotion/styled/macro";
import React, { FunctionComponent } from "react";
import { sortBestRated } from "../../helpers/places";
import { Title2 } from "../../styling/Titles";
import PlaceCard from "./PlaceCard";

interface Props {
  places: Place[];
}

const PlaceList: FunctionComponent<Props> = ({ places = [] }) => {
  const sortedWithRatings = sortBestRated(places);

  return (
    <PlaceListStyled>
      <div className="gap-in-full">
        <Title2>{sortedWithRatings.length ? "Available" : "Well..."}</Title2>
        <span className="best">
          {sortedWithRatings.length
            ? "Best rated on top"
            : "Unfortunately there are no places available"}
        </span>
      </div>
      <ul>
        {sortedWithRatings.map((place) => (
          <li data-testid="place" key={place.slug}>
            <PlaceCard
              place={{
                ...place,
              }}
            ></PlaceCard>
          </li>
        ))}
      </ul>
    </PlaceListStyled>
  );
};

const PlaceListStyled = styled.div`
  .best {
    color: ${(p) => p.theme.colors.main};
  }
  ul {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: ${(p) => p.theme.gutter};
    grid-auto-flow: column;
    grid-auto-columns: calc(300px - ${(p) => p.theme.gutter} * 2);
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    gap: ${(p) => p.theme.gutter};
    max-width: 100vw;
    &::before,
    &::after {
      content: "";
      width: ${(p) => p.theme.gutter};
    }
    padding: 0.4rem 0 1rem;
    @media (min-width: 900px) {
      grid-template-columns: repeat(
        auto-fit,
        calc(300px - ${(p) => p.theme.gutter} * 2)
      );
      grid-auto-flow: initial;
      padding: 1rem;
      justify-content: flex-start;
      gap: 2rem;
      max-width: initial;
      &::before,
      &::after {
        display: none;
      }
    }
  }
`;

export default PlaceList;
