export const getFromLocalStorage = (key: string) => {
  const stored = window.localStorage.getItem(key) || {};

  if (!stored || typeof stored !== "string") {
    return null;
  }

  return JSON.parse(stored);
};

export const saveToLocalStorage = (key: string, data: any) => {
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};
