import styled from "@emotion/styled/macro";

export const BaseButton = styled.button`
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.02) translateY(-1px);
  }
  &:focus {
    transform: scale(0.99) translateY(0);
  }
  &:disabled {
    background: ${(p) => p.theme.colors.text};
    cursor: not-allowed;
    &:hover,
    &:focus {
      transform: initial;
    }
  }
`;

export const Button = styled(BaseButton)`
  background: ${(p) => p.theme.colors.main};
  border-radius: ${(p) => p.theme.border.small};
  color: white;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  display: inline-block;
`;

export const ButtonMain = styled(BaseButton)`
  background: ${(p) => p.theme.colors.background};
  border-radius: ${(p) => p.theme.border.small};
  color: ${(p) => p.theme.colors.main};
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  display: inline-block;
`;

export const FullButton = styled(Button)`
  display: block;
  width: 100%;
`;
