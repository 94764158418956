export const fetchAll = async (token: string) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const { data: users, message } = await res.json();

    return users || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const fetchUser = async (token: string, email: string) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${email}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const { data: user, message } = await res.json();

    return user || message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const updateUser = async (token: string, user: User) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/users/${user.email}`,
      {
        method: "PUT",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...user }),
      }
    );

    const { message } = await res.json();

    return message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};

export const deleteUser = async (token: string, user: User) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/users/${user.email}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    const { message } = await res.json();

    return message;
  } catch (error) {
    console.error(error);
    return "Something went wrong";
  }
};
