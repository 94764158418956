const defaultTheme = {
  gutter: "1rem",
  fonts: {
    primary: "sans-serif",
  },
  colors: {
    black: "black",
    white: "white",
    background: "white",
    text: "#6D7278",
    textOnMain: "white",
    main: "#EF4565",
    secondary: "white",
    grey: "#f5f5f5",
    greyDarker: "#D9D9D9",
    greyEvenDarker: "#A6A6A6",
  },
  border: {
    big: "15px",
    normal: "10px",
    small: "5px",
    smaller: "3px",
  },
  shadows: {
    normal: "0 1px 3px rgba(75, 75, 75, 0.5)",
    bigger: "0 3px 7px rgba(75, 75, 75, 0.6)",
  },
};

export const theme = defaultTheme;

export type DefaultTheme = typeof theme;
