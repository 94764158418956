import { useEffect, useState } from "react";
import useUser from "./useUser";
import {
  addReview,
  updateReview,
  fetchPlace,
  deleteReview,
} from "../api/places";

type OnFetch = () => void;
type OnSaveReview = (review: Review) => void;
type OnRemoveReview = (review: Review) => void;

const usePlaces = (
  slug: Place["slug"]
): [
  {
    place: Place | null;
    isLoading: boolean;
    error: string;
    isEditingReview: boolean;
    isRemovingReview: boolean;
  },
  {
    onSaveReview: OnSaveReview;
    onRemoveReview: OnSaveReview;
  }
] => {
  const [{ user }] = useUser();
  const [place, setPlace] = useState<Place | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEditingReview, setIsEditingReview] = useState(false);
  const [isRemovingReview, setIsRemovingReview] = useState(false);

  const onFetch: OnFetch = async () => {
    if (!user || !user.token || !slug) {
      return;
    }

    const result = await fetchPlace(user?.token, slug);

    if (result && result.slug) {
      setPlace(result);
    } else {
      setError(result);
    }

    setIsLoading(false);
  };

  const onSaveReview: OnSaveReview = async (review) => {
    if (!user || !user.token || !slug) {
      return;
    }
    setIsEditingReview(true);

    if (review.id) {
      await updateReview(user?.token, slug, review);
    } else {
      await addReview(user?.token, slug, review.rating, review.comment);
    }

    onFetch();

    setIsEditingReview(false);
  };

  const onRemoveReview: OnRemoveReview = async (review) => {
    if (!user || !user.token || !slug) {
      return;
    }
    setIsRemovingReview(true);

    deleteReview(user?.token, slug, review);

    onFetch();

    setIsRemovingReview(false);
  };

  useEffect(() => {
    onFetch();
  }, [user]);

  return [
    {
      place,
      isLoading,
      error,
      isEditingReview,
      isRemovingReview,
    },
    {
      onSaveReview,
      onRemoveReview,
    },
  ];
};

export default usePlaces;
