import styled from "@emotion/styled/macro";
import React, { FC } from "react";
import { theme } from "../../styling/theme";
import Icon from "../app/Icon";

interface Props {
  rating?: number;
  onClickStar?: (value: number) => void;
  color?: "main" | "white";
  size?: string;
}

const RatingStars: FC<Props> = ({
  rating,
  onClickStar,
  color = "main",
  size = "1rem",
} = {}) => {
  if (rating == null) {
    return null;
  }

  const stars = Array.from(Array(5)).map((_, index) => {
    const starValue = index + 1;
    if (rating >= starValue) {
      return "full";
    }
    if (rating + 0.5 >= starValue) {
      return "half";
    }
    if (rating < starValue) {
      return "empty";
    }
  });

  return (
    <StarList>
      {stars.map((star, index) => (
        <div
          className="star"
          onClick={() => onClickStar && onClickStar(index + 1)}
          key={`${star}-${index}`}
          data-testid={star}
        >
          <Icon
            size={size}
            color={theme.colors[color]}
            icon={`star-${star}`}
          ></Icon>
        </div>
      ))}
    </StarList>
  );
};

const StarList = styled.div`
  display: flex;
  align-items: center;
  .star {
    cursor: pointer;
  }
`;

export default RatingStars;
