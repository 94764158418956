import styled from "@emotion/styled/macro";
import React, { FC, FormEvent, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { fetchUser, deleteUser, updateUser } from "../api/users";
import Icon from "../components/app/Icon";
import TextInput from "../components/app/TextInput";
import ToggleInput from "../components/app/ToggleInput";
import useUser from "../hooks/useUser";
import { Button } from "../styling/Buttons";
import { Title1 } from "../styling/Titles";

interface Props {
  userEmail?: User["email"];
}

const ManageUser: FC<Props> = ({ userEmail = "" } = {}) => {
  const [{ user }] = useUser();

  const { email: routeEmail } = useParams<{ email: string }>();

  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState(userEmail || routeEmail);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState("");
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  useEffect(() => {
    onFetch();
  }, []);

  if (!user) {
    return <Redirect to="/login"></Redirect>;
  }

  const onFetch = async () => {
    if (!user || !user.token || !email) {
      return;
    }

    const result = await fetchUser(user?.token, email);

    console.log(result);

    if (result && result.email) {
      setEditingUser(result);
      setName(result.name);
      setIsAdmin(result.isAdmin);
    }

    setIsLoadingUser(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!user || !user.token) {
      return;
    }

    setIsSaving(true);

    const result = await updateUser(user.token, {
      ...editingUser,
      name,
      email,
      isAdmin,
    } as User);

    setMessage(result);

    setIsSaving(false);
  };

  const onRemove = async () => {
    if (!user || !user.token || !editingUser) {
      return;
    }

    setIsDeletingUser(true);

    const result = await deleteUser(user.token, editingUser);
    setMessage(result);

    setIsDeletingUser(false);
  };

  return (
    <Page>
      <Title1>Edit user</Title1>
      <p className="result">{message}</p>
      <Form onSubmit={onSubmit}>
        <Input
          label="Name"
          placeHolder="Ex: Joe"
          value={name}
          name="name"
          onChange={(e) => setName(e.currentTarget.value)}
        ></Input>
        <ToggleInput
          toggle={() => setIsAdmin(!isAdmin)}
          toggled={isAdmin}
          name="isAdmin"
          label="Has admin privileges"
        ></ToggleInput>

        <Button data-testid="submit" disabled={isSaving || !name} type="submit">
          {isSaving ? "Wait..." : "Save"}
        </Button>
        <span className="remove" onClick={onRemove}>
          <Icon icon="bin" size="1rem"></Icon>
          {isDeletingUser ? "Removing..." : "Remove user"}
        </span>
      </Form>
    </Page>
  );
};

const Page = styled.div`
  .result {
    color: ${(p) => p.theme.colors.main};
    font-weight: bold;
  }
  .remove {
    cursor: pointer;
  }
`;

const Form = styled.form`
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  max-width: 30rem;
  .validation {
    color: red;
  }
  .redirect {
    text-align: right;
  }
`;

const Input = styled(TextInput)`
  label {
    color: inherit;
  }
`;

export default ManageUser;
