import { useEffect, useState } from "react";
import useUser from "./useUser";
import { fetchAll } from "../api/places";

type OnFetchAll = () => void;

const usePlaces = (): [
  {
    places: Place[];
    isLoading: boolean;
    error: string;
  }
] => {
  const [{ user }] = useUser();
  const [places, setPlaces] = useState<Place[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const onFetchAll: OnFetchAll = async () => {
    if (!user || !user.token) {
      return;
    }
    setIsLoading(true);

    const result = await fetchAll(user?.token);

    if (result && result.length) {
      setPlaces(result);
    } else {
      setError(result);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    onFetchAll();
  }, [user]);

  return [
    {
      places,
      isLoading,
      error,
    },
  ];
};

export default usePlaces;
