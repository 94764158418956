import React, { FC, FormEvent, useState } from "react";
import styled from "@emotion/styled/macro";
import RatingStars from "./RatingStars";
import { Title2 } from "../../styling/Titles";
import { Button } from "../../styling/Buttons";
import TextInput from "../app/TextInput";

interface Props {
  review?: Review | null;
  isSaving: boolean;
  isRemoving: boolean;
  saveReview: (review: Review) => void;
  removeReview: (review: Review) => void;
}

const ReviewForm: FC<Props> = ({
  review = {},
  saveReview,
  isSaving,
  isRemoving,
  removeReview,
}) => {
  const [rating, setRating] = useState(review?.rating || 0);
  const [comment, setComment] = useState(review?.comment || "");

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    saveReview({
      ...review,
      rating,
      comment,
    } as Review);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Title2>{review?.id ? "Edit review" : "Add review"}</Title2>
      <span>Pick a rating from 1 to 5</span>
      <RatingStars
        size="1.5rem"
        rating={rating}
        onClickStar={setRating}
      ></RatingStars>
      <TextInput
        label="Comment"
        placeHolder="A very pleasant experience overall"
        value={comment}
        name="comment"
        onChange={(e) => setComment(e.currentTarget.value)}
        textarea
      ></TextInput>
      <Button role="submit" disabled={!comment || !rating}>
        {isSaving ? "Wait..." : "Save"}
      </Button>
      {!!review?.id && (
        <span className="remove" onClick={() => removeReview(review as Review)}>
          {isRemoving ? "Removing..." : "Remove"}
        </span>
      )}
    </Form>
  );
};

const Form = styled.form`
  display: grid;
  gap: 1rem;
  .remove {
    cursor: pointer;
  }
`;

export default ReviewForm;
