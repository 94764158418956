import styled from "@emotion/styled/macro";
import { format } from "date-fns";
import React, { FC } from "react";
import Icon from "../app/Icon";
import RatingStars from "./RatingStars";

interface Props {
  review: Review;
  onEdit?: (review: Review) => void;
  canEdit?: boolean;
}

const Review: FC<Props> = ({ review, onEdit, canEdit }) => {
  return (
    <ReviewStyled>
      <span>
        {review.userName}{" "}
        {canEdit && (
          <span
            className="edit"
            data-testid="edit"
            onClick={() => onEdit && onEdit(review)}
          >
            <Icon icon="pencil" size="1rem"></Icon>
          </span>
        )}
      </span>
      <p className="date">
        {format(new Date(review.date), "MM/dd/yyyy hh:mm")}
      </p>
      <RatingStars color="main" rating={review.rating}></RatingStars>
      <p>{review.comment}</p>
    </ReviewStyled>
  );
};

const ReviewStyled = styled.div`
  .date {
    font-size: 0.8rem;
  }
  .edit {
    cursor: pointer;
    &:hover {
      svg {
        fill: ${({ theme }) => theme.colors.main};
      }
    }
  }
`;

export default Review;
