import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Global, ThemeProvider } from "@emotion/react/macro";
import { globalStyles } from "./styling/global";
import { theme } from "./styling/theme";
import { UserContextProvider } from "./context/UserContext";

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles}></Global>
        <App />
      </ThemeProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
