import React, { FunctionComponent } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { ButtonMain as Button } from "../../styling/Buttons";
import { Cloudinary } from "cloudinary-core";
import Icon from "../app/Icon";
import settings from "../../settings";
import RatingStars from "./RatingStars";

const cl = new Cloudinary({
  cloud_name: settings.cloudinary.name,
  secure: true,
});

interface Props {
  place: Place;
  buttonText?: string;
}

const PlaceCard: FunctionComponent<Props> = ({
  place,
  buttonText = "view",
}) => {
  return (
    <Link to={`/places/${place.slug}`}>
      <Card title={place.title}>
        <div className="top">
          <img
            src={cl.url(place.photoUrl, {
              width: 400,
              height: 300,
              crop: "fill",
            })}
            alt={place.title}
          />
        </div>
        <div className="body">
          <p className="food">
            <Icon size="1rem" icon="local_restaurant" color="white"></Icon>
            <span>{place.food}</span>
          </p>
          <h3>{place.title}</h3>
          <RatingStars color="white" rating={place.averageRating}></RatingStars>
          <Button>{buttonText}</Button>
        </div>
      </Card>
    </Link>
  );
};

const Card = styled.div`
  display: block;
  box-shadow: ${(p) => p.theme.shadows.normal};
  border-radius: ${(p) => p.theme.border.normal};
  overflow: hidden;
  max-width: 260px;
  transition: all 0.2s;
  margin: 0;
  backface-visibility: hidden;
  &:hover {
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.shadows.bigger};
  }
  .top {
    position: relative;
    height: 12rem;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }
  .body {
    padding: 1rem;
    background: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.textOnMain};
    display: grid;
    gap: 0.5rem;
    .food {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      text-transform: uppercase;
      svg {
        margin-right: 0.5rem;
      }
    }
    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.2;
    }
    .desc {
      color: ${({ theme }) => theme.colors.text};
      font-size: 0.9rem;
    }
    .value {
      font-size: 1rem;
      text-align: center;
    }
    button {
      justify-self: center;
    }
  }
`;

export default PlaceCard;
