import styled from "@emotion/styled/macro";
import React, { FC, FormEvent, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import TextInput from "../components/app/TextInput";
import useUser from "../hooks/useUser";
import { ButtonMain as Button } from "../styling/Buttons";
import { Title2 } from "../styling/Titles";

interface Props {
  isInCreatingMode?: boolean;
}

const AuthPage: FC<Props> = ({ isInCreatingMode = false } = {}) => {
  const [{ user, isLoading, error }, { onSignIn, onRegister }] = useUser();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  if (user) {
    return <Redirect to="/"></Redirect>;
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!isInCreatingMode) {
      return onSignIn(email, password);
    }

    return onRegister(name, email, password);
  };

  const hasErrorOnPassword = !!(
    password &&
    confirmPassword &&
    password !== confirmPassword
  );

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        <Title2>{isInCreatingMode ? "Create your account" : "Sign in"}</Title2>
        {isInCreatingMode && (
          <Input
            label="Name"
            placeHolder="Joe"
            value={name}
            name="name"
            onChange={(e) => setName(e.currentTarget.value)}
          ></Input>
        )}
        <Input
          label="Email"
          placeHolder="joe@example.com"
          value={email}
          name="email"
          type="email"
          onChange={(e) => setEmail(e.currentTarget.value)}
        ></Input>
        <Input
          label="Password"
          placeHolder="supersecret"
          value={password}
          type="password"
          name="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
        ></Input>
        {isInCreatingMode && (
          <Input
            label="Confirm password"
            placeHolder="supersecret"
            value={confirmPassword}
            type="password"
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          ></Input>
        )}
        {hasErrorOnPassword && (
          <p className="validation">Passwords are not matching</p>
        )}
        {error && <p className="validation">{error}</p>}
        <Button
          data-testid="submit"
          disabled={isLoading || hasErrorOnPassword}
          type="submit"
        >
          {isLoading ? "Wait..." : isInCreatingMode ? "Register" : "Sign in"}
        </Button>
        <p className="redirect">
          <Link to={isInCreatingMode ? "/login" : "register"}>
            {isInCreatingMode ? "Sign in" : "Create your account"}
          </Link>
        </p>
      </Form>
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  padding: 2rem;
  box-shadow: ${(p) => p.theme.shadows.normal};
  background: ${(p) => p.theme.colors.main};
  border-radius: ${(p) => p.theme.border.small};
  min-width: 26rem;
  display: grid;
  gap: 1rem;
  color: ${(p) => p.theme.colors.textOnMain};
  h2 {
    text-align: center;
  }
  label,
  a,
  h2 {
    color: inherit;
  }
  .validation {
    color: white;
  }
  .redirect {
    text-align: right;
  }
`;

const Input = styled(TextInput)`
  label {
    color: inherit;
  }
`;

export default AuthPage;
