import styled from "@emotion/styled/macro";
import React, { FC, useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import usePlace from "../hooks/usePlace";
import useUser from "../hooks/useUser";
import { Cloudinary } from "cloudinary-core";
import settings from "../settings";
import Icon from "../components/app/Icon";
import { Title1, Title2, Title3 } from "../styling/Titles";
import RatingStars from "../components/places/RatingStars";
import { getDetailedRatings } from "../helpers/places";
import Review from "../components/places/Review";
import { Button } from "../styling/Buttons";
import Modal from "../components/app/Modal";
import ReviewForm from "../components/places/ReviewForm";

const cl = new Cloudinary({
  cloud_name: settings.cloudinary.name,
  secure: true,
});

interface Props {
  placeSlug?: Place["slug"];
}

const PlacePage: FC<Props> = ({ placeSlug = "" } = {}) => {
  const [{ user }] = useUser();
  const { slug: routeSlug } = useParams<{ slug: string }>();
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [editingReview, setEditingReview] = useState<Review | null>(null);

  const slug = placeSlug || routeSlug;

  const [
    { place, isLoading, error, isEditingReview, isRemovingReview },
    { onSaveReview, onRemoveReview },
  ] = usePlace(slug);

  useEffect(() => {
    setIsAddingReview(false);
    setEditingReview(null);
  }, [place]);

  if (!user) {
    return <Redirect to="/login"></Redirect>;
  }

  if (isLoading || !place) {
    return (
      <Page>
        <p>Loading...</p>
      </Page>
    );
  }

  if (error) {
    return (
      <Page>
        <p>Something went wrong</p>
      </Page>
    );
  }

  const {
    averageRating,
    highestRatedReview,
    lowestRatedReview,
    latestReview,
  } = getDetailedRatings(place);

  return (
    <Page>
      <PlaceHeader>
        {user.isAdmin && (
          <Link
            className="edit"
            data-testid="edit"
            title="Edit place"
            to={`/manage/places/${place.slug}`}
          >
            <span>Edit this place</span>
            <Icon icon="pencil" size="1rem"></Icon>
          </Link>
        )}
        <div className="place-image">
          <img
            src={cl.url(place.photoUrl, {
              width: 800,
              height: 540,
              crop: "fill",
            })}
            alt={place.title}
          />
        </div>
        <p className="food">
          <Icon size="2rem" icon="local_restaurant"></Icon>
          <span>{place.food}</span>
        </p>
        <div className="title">
          <Title1>{place.title}</Title1>
          <RatingStars size="1.4rem" rating={averageRating}></RatingStars>
        </div>
      </PlaceHeader>
      <section>
        <p>{place.desc}</p>
      </section>
      <section className="main-reviews">
        {!!highestRatedReview && (
          <div className="main-review">
            <Title3>Best review</Title3>
            <Review
              canEdit={user.isAdmin}
              onEdit={() => setEditingReview(highestRatedReview)}
              review={highestRatedReview}
            ></Review>
          </div>
        )}
        {!!lowestRatedReview && (
          <div className="main-review">
            <Title3>Worst review</Title3>
            <Review
              canEdit={user.isAdmin}
              onEdit={() => setEditingReview(lowestRatedReview)}
              review={lowestRatedReview}
            ></Review>
          </div>
        )}
        {!!latestReview && (
          <div className="main-review">
            <Title3>Latest review</Title3>
            <Review
              canEdit={user.isAdmin}
              onEdit={() => setEditingReview(latestReview)}
              review={latestReview}
            ></Review>
          </div>
        )}
      </section>
      <section>
        <Button onClick={() => setIsAddingReview(true)}>Add review</Button>
      </section>
      {user.isAdmin && (
        <section className="all-reviews">
          <Title2>All reviews, from newest to oldest</Title2>
          <ul>
            {place.reviews.map((review) => (
              <li key={review.id}>
                <Review
                  canEdit={user.isAdmin}
                  onEdit={() => setEditingReview(review)}
                  review={review}
                ></Review>
              </li>
            ))}
          </ul>
        </section>
      )}
      <Modal
        visible={!!(isAddingReview || editingReview)}
        onClose={() => {
          setIsAddingReview(false);
          setEditingReview(null);
        }}
      >
        <ReviewForm
          review={editingReview}
          isSaving={isEditingReview}
          saveReview={onSaveReview}
          isRemoving={isRemovingReview}
          removeReview={onRemoveReview}
        ></ReviewForm>
      </Modal>
    </Page>
  );
};

const Page = styled.div`
  .main-reviews {
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .main-review {
    margin: 1rem;
    min-width: 6rem;
    max-width: 30rem;
    h3 {
      color: ${({ theme }) => theme.colors.main};
      margin-bottom: 1rem;
    }
  }
  .all-reviews {
    margin-top: 4rem;
    li {
      margin-top: 1rem;
    }
    li {
      :not(:first-of-type) {
        border-top: 1px solid ${(p) => p.theme.colors.greyDarker};
        padding-top: 1rem;
      }
    }
  }
  .edit {
    cursor: pointer;
    color: ${(p) => p.theme.colors.main};
    text-align: right;
    svg {
      margin-left: 0.5rem;
    }
    &:hover {
      svg {
        fill: ${({ theme }) => theme.colors.main};
      }
    }
  }
`;

const PlaceHeader = styled.header`
  display: grid;
  gap: 1.5rem;
  .place-image {
    position: relative;
    img {
      border-radius: ${({ theme }) => theme.border.big};
      border-radius: 10px;
      width: 100%;
      max-height: 36rem;
      object-fit: cover;
    }
  }
  .title {
    display: flex;
    align-items: center;
    h1 {
      margin-right: 1rem;
    }
  }
  .food {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.main};
    font-size: 1rem;
    text-transform: uppercase;
    svg {
      fill: ${({ theme }) => theme.colors.main};
      margin-right: 1rem;
    }
  }
`;
export default PlacePage;
